import React, {useState} from 'react';
import cc from 'classcat';

import {Button, Modal} from '../index';
import { useStore } from '../../hooks';

import IconEmail from '../../images/icons/new-icon-email.svg';
import IconPhone from '../../images/icons/new-icon-phone.svg';

import HeroCTA from './cta';
import HeroWithoutCta from './heroWithoutCta';

import styles from './styles.module.scss';
import ContactForm from "../../components/forms/ContactForm";
import Image from "../../components/layout/Image";

interface Props {
  heroArea: HeroAreaFragment;
  parent?: string;
  headings?: HeadingAnchor[];
  children?: React.ReactNode;
  promoBanner?: PromoBannerFragment;
  isHomePage?: boolean;
}

const Hero = ({ heroArea, parent, headings, children, promoBanner, isHomePage }: Props) => {
  const globals = useStore('globals');
  const heroImage = heroArea.heroImage;
  const retainSbsLayout = heroArea.retainSbsLayout;
  let ctasPresent = false;
  const phone = globals.phone || null;
  const [enquireModal, setEnquireModal] = useState<boolean>(false);

  if (!heroImage) {
    return (
      <div className={cc([styles.hero, styles.heroWithoutImage, 'sectionWrapper'])}>
        <div className={cc(['container', styles.heroContainer])}>
          <div className={styles.heroSimpleInner}>{heroArea.headline && <h1>{heroArea.headline}</h1>}</div>
        </div>
      </div>
    );
  }

  if (heroArea?.children?.length) {
    {heroArea.children.map(
        (child) => child && child['__typename'] == 'hero_cta_BlockType' ? ctasPresent = true : null,
    )}
  }

  if (!ctasPresent || (ctasPresent && retainSbsLayout)) {
    return (
      <HeroWithoutCta heroArea={heroArea} parent={parent} headings={headings}>
        {children}
      </HeroWithoutCta>
    );
  }

  return (
    <>
      <div
        className={cc({
          sectionWrapper: true,
          [styles.withPromoBox]: promoBanner,
          [styles.heroWithCta]: true,
          [styles.heroWithCtaSa]: true,
          'saSection': true,
          [styles.isHomePage]: isHomePage,
          [styles[heroArea?.backgroundColourHero?.trim() || 'backgroundOrange']]: true,
        })}
      >

        {heroImage[0] && (
            <div className={styles.mobileHeroImage}>
              <Image {...heroImage[0]} />
            </div>
        )}

        <div className={cc({
            [styles.heroContentContainerSa]: true
          })}
        >

          <div className={styles.ctaContainer}>
              {heroArea?.children?.map(
                  (child) => child && <HeroCTA key={child.customTitle} cta={child} data-test-id={child.customTitle} name={parent ?? ''} />,
              )}
          </div>

          <div className={styles.contentContainer}>
            {heroArea.customTitle && <h1 data-test-id="customTitle">{heroArea.customTitle}</h1>}

            <div className={styles.copyContainer}>
              {(heroArea.headline || heroArea.simpleText) && (
                  <div className={styles.introduction}>
                    {heroArea.headline && (
                        <p data-test-id="headline" className={cc(['textMimicH3', styles.heroWithoutCtaHeadline])}>
                          {heroArea.headline.split(/\r?\n/).map((line, index) => (
                              <React.Fragment key={index}>
                                {line}
                                <br />
                              </React.Fragment>
                          ))}
                        </p>
                    )}
                    {heroArea.simpleText && (
                        <div className={styles.heroIntroduction} dangerouslySetInnerHTML={{ __html: heroArea.simpleText! }} />
                    )}
                  </div>
              )}

              {heroArea.showContactButtons && (
                <div className={styles.contactContainer}>
                  {phone && (
                    <a href={`tel:${phone.replace(/[^\d\+]/g, '')}`} className={styles.contactButton}>
                      <span className={styles.hideOnMobile}>
                        Call:&nbsp;
                      </span>
                      {phone}
                      <IconPhone />
                    </a>
                  )}

                  <button type="button" onClick={() => setEnquireModal(!enquireModal)} className={styles.contactButton}>
                    <span className={styles.showOnMobile}>
                      Email us
                    </span>
                    <span className={styles.hideOnMobile}>
                      Send us an email
                    </span>
                    <IconEmail />
                  </button>
                  <Modal isOpen={enquireModal} onRequestClose={() => setEnquireModal(false)}>
                    <ContactForm />
                  </Modal>
                </div>
              )}
            </div>

            <div className={cc([ styles.heroBackground ])}>
              <svg className={styles.svgOverlay} preserveAspectRatio="none" viewBox="0 0 687 139" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2605_66)">
                  <path d="M638.521 11.0827C651.011 2.21584 660.504 -1.78297 666 -3H-1V142H501.124C502.789 141.131 508.718 137.41 519.11 129.482C532.1 119.572 566.575 98.726 585.061 71.0648C600.049 48.6367 609.043 28.295 638.521 11.0827Z" fill="inherit"/>
                </g>
                <defs>
                  <clipPath id="clip0_2605_66">
                    <rect width="687" height="139" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <div className={styles.whiteUnderlay}>
                {/* overlaid colours use transparency */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {promoBanner && (
        <div className={styles.promoBanner}>
          <div className={styles.promoBannerContainer}>
            <div className={styles.promoBannerContent}>
              <h2 className={(promoBanner.lockup?.[0]?.url ? styles.srOnly : '')}>
                {promoBanner.heading}
              </h2>
              {promoBanner.lockup?.[0]?.url && (
                  <img loading="lazy" src={promoBanner.lockup?.[0]?.url} alt={promoBanner.heading ? promoBanner.heading : ''} />
              )}
              <div dangerouslySetInnerHTML={{ __html: (promoBanner.paragraph != null ? promoBanner.paragraph : '') }} />
              {promoBanner.buttonDestination?.[0] != null && (
                  <Button type="solidOrange" className={styles.campignButton} href={(promoBanner.buttonDestination?.[0].url != null ? promoBanner.buttonDestination?.[0].url : '')}>
                    {promoBanner.buttonTitle}
                  </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Hero;
