import React from 'react';
import cc from 'classcat';

import styles from './styles.module.scss';

interface Props {
  item: {
    spacer: string;
  };
}

const Spacer = ({ item }: Props) => {
  const spacerValue = item.spacer; // Extract the correct value
  return <div className={cc([styles.spacer, styles[spacerValue] || spacerValue])} />;
};

export default Spacer;
