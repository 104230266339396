import React, { useCallback, useState } from 'react';

import { Button, Modal } from '../../index';
import CourseForm from '../../forms/CourseForm';
import { useSiteQuery } from '../../../hooks';
import { trackEvent, trackAddToCart } from '../../../lib/GA';

import query from './trainingGlobals.graphql';

import styles from './styles.module.scss';
import cc from "classcat";

interface Props {
  title: string;
  showForm: boolean;
  applicationLink: string;
}

const CourseButtons = ({ title, showForm, applicationLink }: Props) => {
  const [{ data }] = useSiteQuery<TrainingGlobalsQuery>({ query });
  const [enquireModal, setEnquireModal] = useState<boolean>(false);

  const trackDownload = useCallback((url: string) => {
    trackEvent('PDF', 'Download', 'Training Fees Download');
    window.open(url, '_blank');
  }, []);

  const applyNowClickHandler = useCallback((url: string, target: string) => {
    trackAddToCart(window, applicationLink, title);
    window.open(url, target);
  }, []);

  if (data?.globalSet) {
    const globalSet = data.globalSet;
    const feesChargesLink = globalSet.feesCharges?.[0]?.url;
    const bookeoEnabled = globalSet?.enableBookeoIntegration!;

    let bookingLink = globalSet.onlineEnrolmentApplication ?? "";
    if (bookeoEnabled && applicationLink) {
      bookingLink = '/courses/booking';
      bookingLink += `?type=${applicationLink}`;
    }

    let feesChargeLabel = `Course Fees & Charges Policy ${new Date().getFullYear()}`;
    if (globalSet.feesChargesDownloadLabel) {
      feesChargeLabel = globalSet.feesChargesDownloadLabel;
    }

    return (
      <div className={styles.buttons}>
        {showForm && (
          <>
            <Button onClick={() => setEnquireModal(!enquireModal)} type="solidOrange" className={styles.button} large>
              Register your interest
            </Button>
            <Modal
              isOpen={enquireModal}
              onRequestClose={() => setEnquireModal(false)}
              overrideTitle={`Register your interest for ${title}`}
            >
              <CourseForm course={title} />
            </Modal>
          </>
        )}
        {!showForm && bookingLink && (
          <Button
            type="solidOrange"
            onClick={() => applyNowClickHandler(bookingLink, bookeoEnabled ? '_self' : '_blank')}
            className={cc([styles.button, styles.solidOrange])}
            large
          >
            Apply now
          </Button>
        )}
        {feesChargesLink && (
          <Button type="orangeBorder" onClick={() => trackDownload(feesChargesLink)} className={cc([styles.button, styles.orangeBorder])} large>
            {feesChargeLabel}
          </Button>
        )}
      </div>
    );
  }
  return null;
};

export default CourseButtons;
