import React from 'react';

import styles from './styles.module.scss';

interface Props {
  item: StepFragment;
  stepNumber: number;
}

const StepsBlock = ({ item, stepNumber }: Props) => {
  return (
    <div className={styles.step}>
        <div className={styles.stepNumber}>
            <span>{stepNumber}</span>
        </div>

        <div className={styles.stepContainer}>
            <div className={styles.stepHeading}>
                {item.title}
            </div>
            {item?.body && <div className={styles.stepBody} dangerouslySetInnerHTML={{ __html: item.body }} />}
        </div>
    </div>
  );
};

export default StepsBlock;
