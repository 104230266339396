import React from 'react';
import cc from 'classcat';

import { EmailButton, PhoneButton } from '../../index';
import { useIsMobile } from '../../../hooks';

import styles from './styles.module.scss';

interface Props {
  headline: string;
  description: string;
  inColumn?: boolean;
}

const CtaPanel = ({ headline, description, inColumn }: Props) => {
  const [isMobile] = useIsMobile(991);

  return (
    <div className={cc([styles.ctaPanel, { [styles.inColumn]: inColumn }])}>
      <h3>{headline}</h3>
      <div dangerouslySetInnerHTML={{ __html: description }} />
      {inColumn ? (
        <div className="row">
          <div className="col-lg-6 col-lg-buttons">
            <PhoneButton className={styles.button} hideText={!isMobile} />
          </div>
          <div className="col-lg-6 col-lg-buttons">
            <EmailButton className={styles.button} text={isMobile ? 'Send us an email' : 'Send an email'} />
          </div>
        </div>
      ) : (
        <React.Fragment>
            <div className={styles.buttonContainer}>
                <div>
                    <PhoneButton className={styles.button} />
                </div>
                <div>
                    <EmailButton className={styles.button} />
                </div>
            </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default CtaPanel;
