import React from 'react';

import ReadmoreIcon from '../../../images/icons/readmore.svg';

import styles from './styles.module.scss';
import cc from "classcat";

interface Props {
  text?: string;
  href: string;
}
const classNamesAlt = cc([styles.readmore, 'newStyleButtonOverrideSolid']);

const Readmore = ({ text, href }: Props) => (
  <a className={classNamesAlt} href={href}>
    {text || 'Find out more'}
    <ReadmoreIcon />
  </a>
);

export default Readmore;
