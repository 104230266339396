import React, { useCallback, useRef, useState } from 'react';
import cc from 'classcat';
import AnimateHeight from 'react-animate-height';
import { useEffectOnce } from 'react-use';
import scrollIntoView from 'scroll-into-view';

import ArrowDownIcon from '../../images/icons/new-caret-down.svg';
import ArrowUpIcon from '../../images/icons/new-caret-up.svg';
import PlusIcon from '../../images/icons/new-plus.svg';
import SubIcon from '../../images/icons/new-minus.svg';

import styles from './styles.module.scss';

interface Props {
  title: string;
  slug?: string;
  content?: string;
  children?: React.ReactNode;
  blockStyle?: boolean;
  className?: string;
}

const Accordion = ({ title, slug, content, children, className }: Props) => {
  const [active, setActive] = useState(false);
  const blockStyle = true;
  const CollapseIcon = blockStyle ? ArrowDownIcon : PlusIcon;
  const CollapsedIcon = blockStyle ? ArrowUpIcon : SubIcon;

  const ref = useRef<HTMLLIElement>(null);
  useEffectOnce(() => {
    if (process.browser && window.location.hash.substring(1) === slug) {
      setActive(true);
      if (ref.current) {
        scrollIntoView(ref.current, { align: { top: 0, topOffset: 80 } });
      }
    }
  });

  const toggleActive = useCallback(() => {
    setActive(!active);
  }, [active]);

  return (
    <li
      ref={ref}
      className={cc([styles.accordion, className, { [styles.active]: active, [styles.blockStyle]: blockStyle }])}
    >
      <div className={styles.accordionItemWrapper}>
        <div
          className={styles.accordionItemHeader}
          role="button"
          tabIndex={0}
          onClick={toggleActive}
          onKeyPress={toggleActive}
        >
          <h3>{title}</h3>
          <div className={styles.accordionButton}>{active ? <CollapsedIcon /> : <CollapseIcon />}</div>
        </div>
        <AnimateHeight duration={200} height={active ? 'auto' : 0}>
          <div className={styles.accordionItemBody}>
            {content ? ( // eslint-disable-next-line react/no-danger
              <p dangerouslySetInnerHTML={{ __html: content }} />
            ) : null}
            {children}
          </div>
        </AnimateHeight>
      </div>
    </li>
  );
};

export default Accordion;
