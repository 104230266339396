import React from 'react';

import { useSiteQuery } from '../../hooks';

import TestimonialsCarousel from './carousel';
import TestimonialsMobile from './mobile';
import query from './testimonials.graphql';

import styles from './styles.module.scss';
import cc from "classcat";

interface Props {
  fragment: TestimonialBlockFragment;
}

const Testimonials = ({ fragment }: Props) => {
  const category = fragment?.testimonialCategories?.[0]?.id || 0;
  const [{ data }] = useSiteQuery<TestimonialsQuery>({ query, variables: { category } });
  const categoryName = data?.category?.title;
  const items = data?.items;

  let headingText = fragment?.headingTextPrimary;
  if (!headingText || !headingText.length) {
      headingText = 'What do our clients say?';
  }

  if (categoryName && items) {
    return (
      <div className={cc([
               styles.testimonials,
               'saSection',
           ])}
      >

        <div className="saContainer">
            <div className="sectionHeading">Testimonials</div>
            <h2 className="sectionTitle">{headingText}</h2>
          <TestimonialsCarousel category={categoryName} testimonials={items} />
          <TestimonialsMobile category={categoryName} testimonials={items} />
        </div>
      </div>

    );
  }
  return null;
};

export default Testimonials;
