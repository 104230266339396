import React, { useState } from 'react';
import cc from 'classcat';
import Spinner from '@atlaskit/spinner';
import { captureException, setExtra } from '@sentry/core';
import { Formik, FormikHelpers } from 'formik';
import { xor } from 'lodash';
import * as Yup from 'yup';

import { Button } from '../../index';
import FormikField from '../FormikField';
import FormikSelect from '../FormikSelect';
import { Heading } from '../Wizard';
import { useStore } from '../../../hooks';
import { trackEvent } from '../../../lib/GA';
import FreeformHelper from '../../../lib/FreeformHelper';

import styles from './styles.module.scss';

interface FeedbackFormValues {
  fullName: string;
  phone: string;
  email: string;
  serviceLocation: string;
  feedbackType: string;
  sourceOfFeedback: string;
  ticks: string[];
  message: string;
  freeform_form_handle: string;
}

const FeedbackForm = () => {
  const globals = useStore('globals');
  const initialValues: FeedbackFormValues = {
    fullName: '',
    phone: '',
    email: '',
    serviceLocation: '',
    feedbackType: '',
    sourceOfFeedback: '',
    ticks: [],
    message: '',
    freeform_form_handle: ''
  };

  const [submitted, setSubmitted] = useState(false);
  const [errored, setErrored] = useState(false);

  const tickOptions = [
    'Management/Staft',
    'Clinical/Care',
    'Lifestyle/Activities',
    'Environment',
    'Documentation',
    'Maintenance',
    'Food',
    'Other',
  ];

  const formHandle = 'feedbackForm';
  const freeForm = globals?.freeform?.find((form) => form.handle === formHandle);

  async function handleSubmit(values: FeedbackFormValues, formikHelpers: FormikHelpers<FeedbackFormValues>) {
    const formData = new FormData();
    // istanbul ignore next
    Object.keys(values).forEach((key) => {
      if (key === 'ticks') {
        formData.append('ticks', values.ticks.join(', '));
      } else {
        formData.append(key, values[key] == null ? '' : values[key]);
      }
    });

    formData.append('handle', formHandle);
    formData.append('origin', globals.siteName || 'Amana Living');

    const response = await FreeformHelper.sendForm(formData, freeForm);
    if (response.success) {
      setSubmitted(true);
      trackEvent('Feedback Form', 'Successful Submission', document.title);
    } else {
      setErrored(true);
      setExtra('errors', response.errors);
      captureException('Feedback Form submission failed');
    }

    formikHelpers.setSubmitting(false);
  }

  function renderConfirmation() {
    return (
      <div className={styles.message}>
        <Heading>Thank you for your feedback!</Heading>
        <p>Thanks! Your feedback has been received and someone from our team will be in touch with you shortly.</p>
      </div>
    );
  }

  function renderError() {
    return (
      <div className={styles.message}>
        <h3>Something went wrong</h3>
        <p>An error occured while trying to submit your enquiry.</p>
        <p>Please try again or call us below.</p>
      </div>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={false}
      validationSchema={FeedbackForm.validationSchema}
      onSubmit={handleSubmit}
      validateOnChange
    >
      {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
        <form
          onSubmit={handleSubmit}
          className={cc({ [styles.feedbackForm]: true, [styles.submitting]: isSubmitting })}
        >
          {submitted && renderConfirmation()}
          {errored && renderError()}
          {!submitted && !errored && (
            <>
              <Heading>How can we make thing better?</Heading>
              <div className="row mt-1">
                <div className="col-lg-6 mb-2 mb-lg-3 customInput">
                  <label htmlFor="fullName">Name</label>
                  <FormikField type="text" name="fullName" required placeholder="e.g. John" />
                </div>

                <div className="col-lg-6 mb-2 mb-lg-3 customInput">
                  <label htmlFor="phone">Phone</label>
                  <FormikField as="input" type="tel" name="phone" required placeholder="e.g. 0404 555 444" />
                </div>
              </div>

              <div className="row mt-0 mt-lg-1">
                <div className="col-lg-6 mb-2 mb-lg-3 customInput">
                  <label htmlFor="email">Email</label>
                  <FormikField type="email" name="email" required placeholder="e.g. john@email.com" />
                </div>

                <div className="col-lg-6 mb-2 mb-lg-3 customInput">
                  <label htmlFor="serviceLocation">Service Location</label>
                  <FormikField type="text" name="serviceLocation" required placeholder="e.g. Head Office" />
                </div>
              </div>

              <div className="row mt-0 mt-lg-1">
                <div className="col-lg-6 mb-2 mb-lg-3 customInput">
                  <label htmlFor="feedbackType">Feedback Type</label>
                  <FormikSelect name="feedbackType" required>
                    <option value="">Select one</option>
                    <option value="Compliment">Compliment</option>
                    <option value="Concern">Concern</option>
                  </FormikSelect>
                </div>

                <div className="col-lg-6 mb-2 mb-lg-3 customInput">
                  <label htmlFor="sourceOfFeedback">Source Of Feedback</label>
                  <FormikSelect name="sourceOfFeedback" required>
                    <option value="">Choose a source</option>
                    <option value="Client/Representative">Client/Representative</option>
                    <option value="Staff member">Staff member</option>
                    <option value="External body">External body</option>
                    <option value="Other">Other</option>
                  </FormikSelect>
                </div>
              </div>

              <div className="row mt-0 mt-lg-1">
                <div className="col-12 mb-2 mb-lg-3 customInput">
                  <p className={styles.label}>
                    Please tick the appropriate box(es) regarding the area you are giving feedback?
                  </p>
                  <div className={styles.ticks}>
                    {tickOptions.map((tick) => (
                      <div key={tick} className={cc({ [styles.checkbox]: true })}>
                        <input
                          id={`tick-${tick}`}
                          name={`tick-${tick}`}
                          type="checkbox"
                          value={tick}
                          onChange={() => setFieldValue('ticks', xor(values.ticks, [tick]))}
                          checked={values.ticks.includes(tick)}
                        />
                        <label htmlFor={`tick-${tick}`}>{tick}</label>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="col-12 customInput">
                  <label htmlFor="message">Your message</label>
                  <textarea
                    name="message"
                    onChange={(e) => setFieldValue('message', e.currentTarget.value)}
                    defaultValue={initialValues.message}
                    placeholder="What would you like to let us know?"
                    maxLength={800}
                  />
                </div>

                <div className="col-12 mt-3">
                  {
                    freeForm?.honeypot ? <input type={'hidden'} name={freeForm.honeypot?.name || ''} value={freeForm.honeypot?.value || ''}/> : null
                  }

                  <Button as="submit" disabled={isSubmitting} type="orangeBorder" large className={'newStyleButtonOverrideSolid'}>
                    Send email
                  </Button>
                </div>
              </div>
            </>
          )}

          <div className={cc({ [styles.spinner]: true, [styles.visible]: isSubmitting })}>
            <Spinner size="xlarge" isCompleting={!isSubmitting} />
          </div>
        </form>
      )}
    </Formik>
  );
};

FeedbackForm.validationSchema = Yup.object().shape({
  fullName: Yup.string().required(),
  phone: Yup.string().required(),
  email: Yup.string().email().required(),
  serviceLocation: Yup.string().required(),
  feedbackType: Yup.string().required(),
  sourceOfFeedback: Yup.string().required(),
  ticks: Yup.array().of(Yup.string()).required(),
  message: Yup.string(),
});

export default FeedbackForm;
