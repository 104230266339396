import React from 'react';

import { Button } from '../../index';
import { useStore } from '../../../hooks';

import PhoneIcon from '../../../images/icons/phone.svg';
import cc from "classcat";

interface Props {
  className?: string;
  hideText?: boolean;
  children?: React.ReactNode;
  customPhone?: string;
}

const PhoneButton = ({ className, hideText, children, customPhone }: Props) => {
  const globals = useStore('globals');
  const phone = customPhone || globals.phone || null;
  const classNames = cc([className, 'newStyleButtonOverrideSolid']);

  return phone ? (
    <Button large href={`tel:${phone.replace(/[^\d\+]/g, '')}`} type="orangeBorder" className={classNames}>
      {children}
      {!children && (
        <>
          <PhoneIcon className={'text-nowrap icon-phone'} />
          {!hideText && <span className={'text-nowrap'} >Call us&nbsp;</span>}
          <strong className={'text-nowrap'}>{phone}</strong>
        </>
      )}
    </Button>
  ) : null;
};

export default PhoneButton;
