import React, { useState } from 'react';
import cc from 'classcat';

interface Props {
  className?: string;
}

const FAQsForm = ({ className }: Props) => {
  const [value, setValue] = useState<string>('');

  function handleUpdate(e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (value.length) {
      window.location.assign(`/help-centre/search?q=${encodeURIComponent(value)}`);
      return;
    }
  }

  return (
    <form className={cc(['searchForm', className])} onSubmit={handleUpdate}>
      <input
        id="searchInput"
        type="text"
        value={value}
        placeholder="Ask a question"
        onChange={(e) => setValue(e.target.value)}
      />
      <button type="submit" aria-label="Search" className={'button-search'}>
      </button>
    </form>
  );
};

export default FAQsForm;
