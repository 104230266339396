import React from 'react';
import cc from 'classcat';
import { captureException } from '@sentry/browser';
import slugify from 'slugify';
import { useQuery } from 'urql';

import { Button } from '../../index';
import query from '../../../graphql/HomeCareServices.graphql';

import Arrow from '../../../images/icons/new-right-caret.svg';

import styles from './styles.module.scss';

interface Props {
  fillColumn: boolean;
}

const HomeCareServicesBlock = ({ fillColumn }: Props) => {
  const [{ data, error }] = useQuery<HomeCareServicesQuery>({ query });
  const availableServcies = '/home-care/our-home-care-services';

  if (error) {
    captureException(error);
    return null;
  }

  if (fillColumn) {
    return (
      <div className={cc({ [styles.fillColumn]: fillColumn })}>
        {data?.entries?.map((cate) => (
          <div
            key={cate?.title}
            className={cc([styles.services, styles.orange])}
          >
            <h3>{cate?.title}</h3>
            <p>{cate?.description}</p>
            <Button type="link" href={availableServcies} className={styles.link}>
              View available services
              <Arrow />
            </Button>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div>
      {data?.entries?.map((cate) => (
        <div
          key={cate?.title}
          className={cc([styles.services, styles.orange])}
        >
          <h3>{cate?.title}</h3>
          <div className={styles.items}>
            {cate?.services?.slice(0, 4).map((s) => (
              <a
                key={s?.title}
                href={`${availableServcies}#${slugify(s?.title || '').toLowerCase()}`}
                className={styles.item}
              >
                <div className={styles.icon}>
                  {s?.icon?.[0]?.url && <img loading="lazy" src={s?.icon?.[0]?.url} alt={s?.title || undefined} />}
                </div>
                <p className="service-label">{s?.title}</p>
              </a>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default HomeCareServicesBlock;
