import React from 'react';
import scrollIntoView from 'scroll-into-view';

import { Button } from '../../index';
import { useIsMobile } from '../../../hooks';
import { urlFor } from '../../../lib/Link';

import styles from './styles.module.scss';

interface Props {
  item: AccordionItemFragment;
}

const AccordionLink = ({ item }: Props) => {
  const linkField = item?.linkField?.[0];
  const [isMobile] = useIsMobile(768);

  const scrollToSection = (slug: string) => {
    const isRowSection = slug.startsWith('row-section');
    const sectionPadding = isRowSection ? (isMobile ? 40 : 160) : isMobile ? 0 : 80;

    if (document.getElementById(slug)) {
      scrollIntoView(document.getElementById(slug)!, { align: { top: 0, topOffset: sectionPadding } });
    }
  };

  if (linkField) {
    const props =
      urlFor(linkField).indexOf('/#') === 0
        ? {
            onClick: () => scrollToSection(urlFor(linkField).replace('/#', '')),
          }
        : {
            href: urlFor(linkField),
          };

    return (
      <p>
        <Button type="link" {...props} className={styles.link}>
          Learn more
            <svg width="12" height="28" viewBox="0 0 12 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.633715 0.307186C1.32428 -0.210867 2.29461 -0.0581523 2.80102 0.648284L11.6998 13.062C12.1001 13.6203 12.1001 14.3797 11.6998 14.938L2.80102 27.3517C2.29461 28.0582 1.32428 28.2109 0.633715 27.6928C-0.0568454 27.1748 -0.206128 26.1821 0.300283 25.4757L8.52666 14L0.300283 2.52432C-0.206128 1.81788 -0.0568454 0.825239 0.633715 0.307186Z" fill="#DC582E"/>
            </svg>
        </Button>
      </p>
    );
  }
  return null;
};

export default AccordionLink;
