import React, {useState} from 'react';
import styles from './styles.module.scss';
import {Button, Modal} from "../../index";
import ContactForm from "../../forms/ContactForm";
import {useStore} from "../../../hooks";
import PhoneIcon from "@/images/icons/phone.svg";
import EmailIcon from "@/images/icons/email.svg";
import cc from "classcat";

interface Props {
    shortText: string;
    title: string;
    formTitle: string,
}

const SalesFormCta = ({ shortText, title, formTitle }: Props) => {
    const [enquireModal, setEnquireModal] = useState<boolean>(false);
    const globals = useStore('globals');

    return (
        <div className={cc(['sectionWrapper section-purple', styles.py])}>
            <div className="container">
                <div className={styles.contentHolder}>
                    <div className={styles.textHolder}>
                        <h3>
                            {title}
                        </h3>

                        <p>
                            {shortText}
                        </p>
                    </div>

                    <div className={styles.ctaHolder}>
                        <Button onClick={() => {setEnquireModal(true)}} large type="solidOrange" className={'newStyleButtonOverrideSolid'}>
                            <EmailIcon />
                            Send an email
                        </Button>

                        <Button href={`tel:${globals.phone}`} large type="orangeBorder" className={'newStyleButtonOverrideSolid'}>
                            <PhoneIcon />
                            {globals.phone}
                        </Button>
                    </div>
                </div>
            </div>

            <Modal isOpen={enquireModal} onRequestClose={() => setEnquireModal(false)} title={formTitle}>
                <ContactForm section={'Sales Form'} title={formTitle}/>
            </Modal>
        </div>
    );
};

export default SalesFormCta;
