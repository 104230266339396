import React from 'react';
import cc from 'classcat';
import scrollIntoView from 'scroll-into-view';

import Image from '../layout/Image';
import {useIsMobile, useStore} from '../../hooks';

import styles from './styles.module.scss';
import HeroCTA from "./cta";
import PhoneIcon from "@/images/icons/phone.svg";
import Button from "../buttons/Button";
import ContactButtons from "../ContactButtons";

interface Props {
  heroArea: HeroAreaFragment;
  parent?: string;
  headings?: HeadingAnchor[];
  children?: React.ReactNode;
}

const HeroWithoutCta = ({ heroArea, parent, headings, children }: Props) => {
  const [isMobile] = useIsMobile(768);
  const heroImage = heroArea.heroImage;
  const headline = heroArea.headline!;
  const retainSbsLayout = heroArea.retainSbsLayout;
  const globals = useStore('globals');

  const scrollToSection = (slug: string) => {
    const isRowSection = slug.startsWith('row-section');
    const sectionPadding = isRowSection ? (isMobile ? 40 : 160) : isMobile ? 0 : 80;

    if (document.getElementById(slug)) {
      scrollIntoView(document.getElementById(slug)!, { align: { top: 0, topOffset: sectionPadding } });
    }
  };

  const showButton = !!heroArea?.children?.find(( child) => {
    return child?.linkToSalesForm;
  });

  return (
      <div className={cc([
          'sectionWrapper',
        styles.hero,
        styles.heroWithoutCta,
        [styles[heroArea?.backgroundColourHero || 'backgroundOrange']],
      ])}>
        <div className={cc(['container', styles.heroContainer])}>
          <div className="row">
            <div className="col-lg-6">
              <div className="sectionHeading">
                {parent}
              </div>
              {heroArea.customTitle && (
                  <h1 className={cc(["heroH1", styles.heroH1])} data-test-id="customTitle">
                    {heroArea.customTitle}
                  </h1>
              )}
              {(heroArea.headline || heroArea.simpleText) && (
                  <div className={styles.introduction}>
                    {heroArea.headline && (
                      <p className={cc(["heroHeadline", 'textMimicH3', styles.heroHeadline])} data-test-id="headline">
                        {heroArea.headline}
                      </p>
                    )}
                    {heroArea.simpleText && (
                        <div className={styles.heroIntroduction} dangerouslySetInnerHTML={{ __html: heroArea.simpleText! }} />
                    )}
                  </div>
              )}

              <div className={styles.ctaHolder}>
                {retainSbsLayout && (
                    <div className={styles.retainSbsCtas}>
                      {heroArea?.children?.map(
                          (child) => child && <HeroCTA theme={'newStyleButtonOverrideSolid'} key={child.customTitle} cta={child} data-test-id={child.customTitle} name={parent ?? ''} />,
                      )}

                      { showButton ?
                        <Button href={`tel:${globals.phone}`} large type="orangeBorder" className={'newStyleButtonOverrideSolid'}>
                          <PhoneIcon />
                          {globals.phone}
                        </Button>
                      : null }
                    </div>
                )}

                {heroArea.toggle && (
                    <div className={styles.toggle}>
                      <p>On this page.</p>
                      <ul className={styles.pageList}>
                        {headings?.map((heading) => (
                            <li>
                              <button key={heading.title} type="button" onClickCapture={() => scrollToSection(heading.slug)}>
                                {heading.title}
                              </button>
                            </li>
                        ))}
                      </ul>
                    </div>
                )}

                {children}
              </div>
            </div>
            {(heroImage[0] || heroArea.showContactButtons) && (
                <div className={cc(["col-lg-6", styles.orderFirstMobile])}>
                  {heroImage[0] && (
                      <div className={styles.heroImage}>
                        <Image {...heroImage[0]} alt={headline} />
                      </div>
                  )}
                  {heroArea.showContactButtons && (
                      <div className={styles.contactButtons}>
                        <ContactButtons />
                      </div>
                  )}
                </div>
            )}
          </div>
        </div>

        <div className={cc([ styles.heroBackground ])}>
          <svg className={styles.svgOverlay} preserveAspectRatio="none" viewBox="0 0 732 542" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 541.5V0H732C688.5 20.6667 572.607 97.6433 429.5 205.5C293.5 308 264.5 336 174.5 470.5C129.961 537.061 87 537 63.5 541.5H0Z" fill="inherit"/>
          </svg>
          <div className={styles.whiteUnderlay}>
            {/* overlaid colours use transparency */}
          </div>
        </div>
      </div>
  );
};

export default HeroWithoutCta;
