import React, { useState } from 'react';

import { Button, Modal } from '../../index';
import FeedbackForm from '../../forms/FeedbackForm';
import {useSearchParam} from "react-use";
import cc from "classcat";

interface Props {
  className?: string;
}

const FeedbackButton = ({ className }: Props) => {
  const queryParam = useSearchParam('openFeedback');
  const [feedbackModal, setFeedbackModal] = useState<boolean>(queryParam !== null);

  return (
    <>
        <Button onClick={() => setFeedbackModal(!feedbackModal)} className={cc([className, 'newStyleButtonOverrideSolid'])} type="solidOrange" large>
        Provide Feedback
      </Button>
      <Modal isOpen={feedbackModal} onRequestClose={() => setFeedbackModal(false)} overrideTitle="Feedback">
        <FeedbackForm />
      </Modal>
    </>
  );
};

export default FeedbackButton;
