import React, { useState } from 'react';
import cc from 'classcat';
import { useRouter } from 'next/router';

import { Button } from '../../index';

import styles from './styles.module.scss';

const SearchBlock = () => {
  const router = useRouter();
  const initQuery = (router.query?.q as string) || '';
  const [value, setValue] = useState<string>(initQuery);

  const isNursingHome = router.asPath.indexOf('nursing-homes') !== -1;
  const locationUri = `/${isNursingHome ? 'nursing-homes' : 'retirement-villages'}/locations`;
  const isLocationsPage = router.asPath.indexOf('/locations') !== -1;

  function handleUpdate(e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (value.length) {
      window.location.assign(`${locationUri}?q=${encodeURIComponent(value)}`);
      return;
    }
  }

  return (
    <div className={styles.searchBlock}>
      <p>
        <strong>{isNursingHome ? 'Find your nearest aged care home' : 'Find your nearest location'}</strong>
      </p>
      <form className={cc(['searchForm', styles.search])} onSubmit={handleUpdate}>
        <input
          id="searchInput"
          type="text"
          value={value}
          placeholder="e.g. Perth or 6000"
          onChange={(e) => setValue(e.target.value)}
        />
        <button type="submit" aria-label="Search">
        </button>
      </form>
      {!isLocationsPage ? (
        <Button href={locationUri} type="link" includeArrow>
          See all our locations
        </Button>
      ) : (
        <p className={styles.below}>Locations ordered by the distance from your postcode</p>
      )}
    </div>
  );
};

export default SearchBlock;
