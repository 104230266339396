import React from 'react';
import cc from 'classcat';

import { EmailButton, PhoneButton } from '../index';

import PhoneIcon from '../../images/icons/phone.svg';

import styles from './styles.module.scss';

interface Props {
  desktop?: boolean;
  mobile?: boolean;
  children?: React.ReactNode;
  customPhoneNumber?: string;
  customEmailDesktop?: JSX.Element;
  customEmailMobile?: JSX.Element;
}

const ContactButtons = ({
  desktop,
  mobile,
  children,
  customPhoneNumber,
  customEmailDesktop,
  customEmailMobile,
}: Props) => (
  <>
    <div
      className={cc([
        'row',
        'desktopOnly',
        { [styles.desktopButtons]: true, [styles.hideDesktop]: !desktop && mobile },
          styles.hideDesktopButtons
      ])}
    >
      {children && <div className="container">{children}</div>}
      <div className={cc([
          styles.buttonContainerDesktop,
      ])}>
          <PhoneButton customPhone={customPhoneNumber} className={'text-nowrap'} />
          {customEmailDesktop ? customEmailDesktop : <EmailButton />}
      </div>
    </div>
    <div
      className={cc(['row', 'mobileOnly', { [styles.mobileButtons]: true, [styles.hideMobile]: !mobile && desktop }])}
    >
      {children && <div className="container">{children}</div>}
        <div className={cc([
            styles.buttonContainerMobile,
        ])}>
            <PhoneButton customPhone={customPhoneNumber}>
                <PhoneIcon /> Call us
            </PhoneButton>
            {customEmailMobile ? customEmailMobile : <EmailButton text="Email us" />}
      </div>
    </div>
  </>
);

export default ContactButtons;
