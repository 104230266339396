import React from 'react';
import cc from 'classcat';
import scrollIntoView from 'scroll-into-view';

import { useIsMobile } from '../../hooks';

import ButtonBlock from '../PageContent/ButtonBlock';
import PhotoCarousel from '../PhotoCarousel';

import styles from './styles.module.scss';
import ContactButtons from "../ContactButtons";

interface Props {
  sectionHeading: string;
  title: string;
  headline: string;
  headings?: HeadingAnchor[];
  photos?: Maybe<AssetFragment>[];
  buttons?: Maybe<Array<Maybe<FeesButtonFragment>>>;
  showContactButtons?: boolean;
  additionalClasses?: string | null;
}

const HeroLocation = ({ sectionHeading, title, headline, headings, photos, buttons, showContactButtons, additionalClasses }: Props) => {
  const [isMobile] = useIsMobile(768);

  const scrollToSection = (slug: string) => {
    const sectionPadding = isMobile ? 40 : 160;

    if (document.getElementById(slug)) {
      scrollIntoView(document.getElementById(slug)!, { align: { top: 0, topOffset: sectionPadding } });
    }
  };

  return (
    <div className={cc(['sectionWrapper', styles.hero, styles.heroWithoutCta, 'heroLocation', additionalClasses])}>
      <div className={cc(['container', styles.heroContainer])}>
        <div className="row">
          <div className="col-lg-6">
            <div className="sectionHeading">{sectionHeading}</div>
            {(() => {
              const [firstPart, secondPart] = title.split(/,(.+)/);
              return (
                  <h1 className="heroH1 twoPart" data-test-id="customTitle">
                    {firstPart}
                    {secondPart && (
                        <>
                          <br />
                          <span>{secondPart.trim()}</span>
                        </>
                    )}
                  </h1>
              );
            })()}
            <div className={styles.contentContainer}>
              <p className="heroHeadline" data-test-id="headline">
                {headline}
              </p>
              <div className={styles.toggle}>
                {!!headings?.length && (
                    <>
                      <p>On this page</p>
                      <ul className={styles.pageList}>
                        {headings.map((heading) => (
                            <li key={heading.title}>
                              <button type="button" onClickCapture={() => scrollToSection(heading.slug)}>
                                {heading.title}
                              </button>
                            </li>
                        ))}
                      </ul>
                    </>
                )}
              </div>
              {buttons?.map((button) => button && <ButtonBlock key={button.uid!} item={button} />)}
            </div>
          </div>
          <div className={cc(["col-lg-6", styles.orderFirstMobile])}>
            <div className={styles.heroImage}>
              {photos && (
                <PhotoCarousel photos={photos.filter((p): p is AssetFragment => p !== null && p !== undefined)} />
              )}
            </div>

            {
              showContactButtons &&
              <div className={cc([styles.contactButtons, styles.locationHeader])}>
                <ContactButtons />
              </div>
            }
          </div>
        </div>
      </div>
      <div data-hero-background="true" className={cc([ styles.heroBackground ])}>
        <svg className={styles.svgOverlay} preserveAspectRatio="none" viewBox="0 0 732 542" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 541.5V0H732C688.5 20.6667 572.607 97.6433 429.5 205.5C293.5 308 264.5 336 174.5 470.5C129.961 537.061 87 537 63.5 541.5H0Z" fill="inherit"/>
        </svg>
        <div className={styles.whiteUnderlay}>
          {/* overlaid colours use transparency */}
        </div>
      </div>
    </div>
  );
};

export default HeroLocation;
